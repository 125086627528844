import React, { useState } from 'react';
import SearchBar from '../components/SearchBar';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Banner from '../components/Banner';
import QuestionList from '../components/QuestionList';
import { useIntl } from 'gatsby-plugin-intl';
import { Grid, Card, Loader } from '@paygreen/paygreen-ui';

const SearchPage = ({ location }) => {
    const intl = useIntl();

    const [isSearching, setIsSearching] = useState(false);
    const [questions, setQuestions] = useState([]);

    // Retrieve searched terms from url with location.search
    const [searchTerms, setSearchTerms] = useState(
        location.search
            .substring(3)
            .split('+')
            .join(' '),
    );

    const breadcrumb = [
        {
            url: '/',
            label: intl.formatMessage({ id: 'home.title' }),
        },
        {
            url: '#',
            label: intl.formatMessage({ id: 'search.title' }),
        },
    ];

    return (
        <Layout>
            <SEO
                metaTitle={intl.formatMessage({ id: 'search.title' })}
                description={intl.formatMessage({ id: 'search.description' })}
                ogTitle={intl.formatMessage({ id: 'search.title' })}
                ogDescription={intl.formatMessage({ id: 'search.description' })}
                ogImage={null}
                ogUrl={null}
            />
            <Banner
                name={intl.formatMessage({ id: 'search.title' })}
                description={intl.formatMessage({ id: 'search.description' })}
                breadcrumb={breadcrumb}
                theme="primary"
                gradient="brand"
            />
            <Grid justifyContent="center">
                <SearchBar
                    onResponse={setQuestions}
                    onChange={setSearchTerms}
                    isSearching={setIsSearching}
                    value={searchTerms}
                    hasShadow
                />
            </Grid>
            {searchTerms.length > 0 ? (
                <>
                    {isSearching ? (
                        <Loader
                            loaderSize="lg"
                            paddingTop="md"
                            paddingBottom="md"
                        />
                    ) : (
                        <Grid
                            justifyContent="center"
                            childrenMargin="none"
                            childrenMarginBig="none"
                        >
                            <Card hasBackground={false} blockWidth="xl">
                                <QuestionList questions={questions} />
                            </Card>
                        </Grid>
                    )}
                </>
            ) : null}
        </Layout>
    );
};

export default SearchPage;
