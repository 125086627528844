import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import { Input } from '@paygreen/paygreen-ui';
import { fetchQuestions } from '../utils/prismicQueries';
import useDebounce from '../utils/debouncer';
import { parsePrismic } from '../utils/parsers/questionsParser';
import { BuildDateContext } from './Layout';

const SearchBar = ({ onResponse, value, onChange, isSearching, hasShadow, ...rest }) => {
    const buildDateContext = useContext(BuildDateContext);

    const intl = useIntl();
    const [searchTerms, setSearchTerms] = useState(value);
    const debouncedSearchTerms = useDebounce(searchTerms, 500);

    useEffect(() => {
        if (onChange) {
            onChange(debouncedSearchTerms.trim());
        }

        if (debouncedSearchTerms.trim().length === 0) {
            onResponse([]);
        } else {
            if (isSearching) {
                isSearching(true);
            }
            fetchQuestions(debouncedSearchTerms.trim(), buildDateContext)
                .then(results => {
                    const questions = [];

                    results.forEach(question => {
                        questions.push(parsePrismic(question));
                    });

                    onResponse(questions);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    if (isSearching) {
                        isSearching(false);
                    }
                });
        }
    }, [isSearching, onChange, onResponse, debouncedSearchTerms, buildDateContext]);

    return (
        <Input
            data-testid="searchbar"
            placeholder={intl.formatMessage({ id: 'searchbar.placeholder' })}
            onChange={e => {
                setSearchTerms(e.target.value);
            }}
            value={searchTerms}
            hasShadow={hasShadow}
            {...rest}
        />
    );
};

SearchBar.propTypes = {
    onResponse: PropTypes.func.isRequired,
    value: PropTypes.string,
    hasShadow: PropTypes.bool,
};

SearchBar.defaultProps = {
    value: '',
    hasShadow: false,
};

export default SearchBar;
